import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #d2d2d2;
  padding: 3px;
  border-radius: 25px;
`;

const Button = styled.button`
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 25px;
  background: black;
  color: white;
`;

const ButtonText = styled.span`
  font-size: 22px;
`;

const ValueText = styled.span`
  font-size: 22px;
  margin: 6px;
`;

const InputNumeric = ({
  value,
  incValue,
  decValue,
  orgValue,
  disabled,
  maxValue = 99,
  minValue = 0,
}) => {
  return (
    <>
      <Container>
        {!disabled && (
          <Button
            onClick={decValue}
            disabled={parseInt(value) <= parseInt(minValue)}
          >
            <ButtonText>-</ButtonText>
          </Button>
        )}
        <ValueText>
          {value}
          {parseInt(value) !== parseInt(orgValue) && ` (${orgValue})`}
        </ValueText>
        {!disabled && (
          <Button
            onClick={incValue}
            disabled={parseInt(value) >= parseInt(maxValue)}
          >
            <ButtonText>+</ButtonText>
          </Button>
        )}
      </Container>
    </>
  );
};

export default InputNumeric;
