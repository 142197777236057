import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    font-size: 22px;
    color: red;
  }
`;

const Checkbox = ({ value, onChange }) => {
  return (
    <Container>
      <input type="checkbox" value={value} onChange={onChange} />
    </Container>
  );
};

export default Checkbox;
