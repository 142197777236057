import React from "react";
import styled from "styled-components";

import { ReactComponent as IconLocation } from "../../images/location.svg";

const LocationIcon = styled(IconLocation)`
  fill: ${(props) => props.theme.white};
  width: 30px;
  height: 30px;
`;

const handleOpenLocation = ({ location }) => {
  const { address, zip, city } = location;

  if (!address || !zip || !city) return <></>;

  const url = encodeURI(
    `https://www.google.com/maps/dir//${address},+${zip}+${city}`
  );

  window.open(url, "_blank");
};

const DriverStopLocation = ({ location }) => {
  return <LocationIcon onClick={() => handleOpenLocation({ location })} />;
};

export default DriverStopLocation;
