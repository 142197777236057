import styled from "styled-components";

const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 15px;
`;

export default Item;
