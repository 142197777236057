import styled from "styled-components";

const Textarea = styled.textarea`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  color: ${props => props.theme.darkBlue};
  border: 1px ${props => props.theme.lightgrey} solid;
  border-radius: 4px;
  padding: 15px;
  font-size: 1rem;
  outline: none;
  background-color: #fafafa;

  &:focus {
    border: 1px #d5d5d5 solid;
  }

  &:disabled {
    color: ${props => props.theme.lightgrey};
    background-color: ${props => props.theme.lightwhite};
    border: 1px ${props => props.theme.lightgrey} solid;
  }

  &:-webkit-autofill,
  -webkit-autofill:hover,
  -webkit-autofill:focus,
  -webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
    box-shadow: 0 0 0 40px white inset !important;
    font-size: 1rem;
  }
`;

export default Textarea;
