import React from "react";
import styled from "styled-components";

import { ReactComponent as WarningSVG } from "../../images/warning.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 14px;
  padding: 4rem;
`;

const Icon = styled(WarningSVG)`
  fill: ${props => props.theme.grey};
`;

const Text = styled.div`
  text-align: center;
  margin-top: 1rem;
  color: ${props => props.theme.grey};
  font-size: 1.25rem;
`;

const NotFound = ({ text }) => (
  <Container>
    <Icon />
    <Text>{text}</Text>
  </Container>
);

export default NotFound;
