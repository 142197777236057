import React, { useContext, useEffect } from "react";

import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";

import { AuthContext, OrderContext } from "../../context";

import { LoadingContainer } from "../../styles";

import { BACKEND_URL } from "../../config";

const Logout = props => {
  const { authenticated, setAuthenticated, setUser } = useContext(AuthContext);
  const { setOrders, setOrdersDefault, setLoadingOrders } = useContext(
    OrderContext
  );

  useEffect(() => {
    const handleLogout = async () => {
      const result = await fetch(`${BACKEND_URL}/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include"
      });

      if (result && result.status === 200) {
        setUser(null);
        setOrders([]);
        setOrdersDefault([]);
        setLoadingOrders(true);
        setAuthenticated(false);
        props.history.push("/login");
      } else {
        props.history.push("/");
      }
    };

    if (authenticated) {
      handleLogout();
    } else {
      props.history.go("/login");
    }
  }, [
    setUser,
    setAuthenticated,
    authenticated,
    setOrders,
    setOrdersDefault,
    setLoadingOrders,
    props.history
  ]);

  return (
    <LoadingContainer>
      <Loader type="Oval" color="#19769f" height={140} width={140} />
      <br />
      Loggar ut...
    </LoadingContainer>
  );
};

export default withRouter(Logout);
