import { useState, useEffect, useContext } from "react";

import { BACKEND_URL } from "../../config";
import { AuthContext } from "../../context";

const useFetch = ({ path, body }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [refetch, setRefetch] = useState();
  const { setError, error } = useContext(AuthContext);

  useEffect(() => {
    const runFetch = async () => {
      try {
        const res = await fetch(`${BACKEND_URL}${path}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include",
          body
        });

        if (res.status !== 200) {
          setError(res.statusText);
          throw new Error(res.statusText);
        }

        const json = await res.json();

        if (json && json.data) {
          setData(json.data);
        }

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
        console.error(error.message);
      }
    };

    runFetch();
  }, [path, body, setError, refetch]);

  return [{ data, loading, error }, setRefetch];
};

export default useFetch;
