import styled from "styled-components";

const Button = styled.button`
  color: ${props => props.theme.white};
  border: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  height: ${props => (props.small ? "24px" : "40px")};
  width: ${props => (props.width ? props.width : "unset")};
  font-size: ${props => (props.small ? "0.8rem" : "1rem")};

  box-shadow: rgba(0, 0, 0, 0.16) 0px 9px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 9px 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 9px 10px;

  text-decoration: none;
  outline: none;
  display: inline-block;

  background-color: ${props => props.theme.blue};
  background-image: -webkit-gradient(
    linear,
    left,
    right,
    from(${props => props.theme.blue}),
    to(${props => props.theme.darkblue})
  );
  background-image: -webkit-linear-gradient(
    top,
    ${props => props.theme.blue},
    ${props => props.theme.darkblue}
  );
  background-image: -moz-linear-gradient(
    top,
    ${props => props.theme.blue},
    ${props => props.theme.darkblue}
  );
  background-image: -ms-linear-gradient(
    top,
    ${props => props.theme.blue},
    ${props => props.theme.darkblue}
  );
  background-image: -o-linear-gradient(
    top,
    ${props => props.theme.blue},
    ${props => props.theme.darkblue}
  );
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.blue},
    ${props => props.theme.darkblue}
  );
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=${props =>
    props.theme.blue}, endColorstr=${props => props.theme.darkblue});

    &:disabled {
      background-color:${props => props.theme.grey};
    }
`;

export default Button;
