import React from "react";

import {
  Container,
  Card,
  Row,
  Column,
  Item,
  Text,
  TextPrimary,
  Label,
  Line,
  Spacer
} from "../../styles";

import { OrderTypeIcon } from "../../components";

const OrderDetails = props => {
  const { order } = props;

  return (
    <Container>
      <Spacer horizontal={7} />
      <Card>
        <Row>
          <Item>
            <Column style={{ alignItems: "center" }}>
              <img
                src="/images/hospital.png"
                alt="Patient"
                height="30"
                width="30"
                style={{ marginRight: "0.5rem" }}
              />
              <TextPrimary>
                {order.firstName} {order.lastName}
              </TextPrimary>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <OrderTypeIcon type={order.type} />
              </div>
            </Column>
          </Item>
        </Row>
        <Line />
        <Row>
          <Item>
            <Label>Personnummer</Label>
            <Text>{order.personId ? order.personId + "-****" : ""}</Text>
          </Item>
          <Item>
            <Label>Status</Label>
            <Text>{order.status}</Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Label>Klinik</Label>
            <Text>{order.clinic}</Text>
          </Item>
          <Item>
            <Label>Lab</Label>
            <Text>{order.lab}</Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Label>Tandläkare</Label>
            <Text>{order.dentist}</Text>
          </Item>
          <Item>
            <Label>Pricktid</Label>
            <Text>{order.dotTime}</Text>
          </Item>
        </Row>
        <Row>
          <Item>
            <Label>Beskrivning</Label>
            <Text>{order.description}</Text>
          </Item>
        </Row>
      </Card>
    </Container>
  );
};

export default OrderDetails;
