import React, { useEffect, useContext, useState, Fragment } from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import Pullable from "react-pullable";

import { BACKEND_URL } from "../../config";
import { OrderContext } from "../../context";
import { Button, LoadingContainer, TextHeader, Spacer } from "../../styles";
import { OrderTabs, OrderItem, SearchInput } from "../../components";

import { ReactComponent as SearchSVG } from "../../images/search.svg";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchResult = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.grey};
  margin-top: 4px;
  margin-left: 4px;
`;

const NoOrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 14px;
  padding: 4rem;
`;

const NoOrdersIcon = styled(SearchSVG)`
  fill: ${(props) => props.theme.grey};
`;

const NoOrdersText = styled.div`
  margin-top: 1rem;
  color: ${(props) => props.theme.grey};
  font-size: 1.25rem;
`;

const OrderSearch = ({ searchFor }) => {
  const {
    searchOrders,
    setSearchOrders,
    searchText,
    setSearchText,
    searched,
    setSearched,
  } = useContext(OrderContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async (e) => {
    e.preventDefault();

    let qual;
    let searchValue = searchText || searchFor;

    if (searchValue && searchValue.length < 4) {
      alert("Ange fler än 3 tecken för att söka");
      return;
    }

    setLoading(true);
    setError(null);
    setSearched(false);

    qual = encodeURIComponent(
      `'Request ID' like "%${searchValue}%" or 'Patient_FirstName' like "%${searchValue}%" or 'Patient_LastName' like "%${searchValue}%" or 'Patient_PersonID' like "%${searchValue}%"`
    );

    try {
      const res = await fetch(`${BACKEND_URL}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          qual,
        }),
      });

      if (res.status !== 200) {
        setError(res.statusText);
        throw new Error(res.statusText);
      }

      const { status, data, error } = await res.json();

      if (status === 200) {
        setSearchOrders(data);
      } else {
        setError(error);
      }

      setLoading(false);
      setSearched(true);
    } catch (error) {
      setError(error);
      setLoading(false);
      setSearched(true);
    }
  };

  const handleClearSearch = (e) => {
    e.preventDefault();

    setSearchText("");
    setSearchOrders([]);
    setSearched(false);
  };

  useEffect(() => {
    if (searchFor && !searchText) {
      setSearchText(searchFor);
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSerchTextChange = (e) => {
    setSearched(false);
    setSearchText(e.target.value);
  };

  if (loading) {
    return (
      <LoadingContainer>
        <Loader type="Oval" color="#19769f" height={140} width={140} />
        <br />
        Söker efter ordrar...
      </LoadingContainer>
    );
  }

  return (
    <Fragment>
      <OrderTabs />
      <SearchContainer>
        <SearchBar>
          <form onSubmit={(e) => handleSearch(e)} style={{ flex: 1 }}>
            <SearchInput
              type="text"
              placeholder="Ange sökord..."
              name="search"
              value={searchText}
              onChange={handleSerchTextChange}
              onClear={handleClearSearch}
            />
          </form>
          <Button
            onClick={handleSearch}
            style={{ width: "80px", marginLeft: "10px" }}
            disabled={loading || searchText.length < 4}
          >
            {loading ? "Söker..." : "Sök"}
          </Button>
        </SearchBar>
        {searchText.length === 0 && searchOrders.length === 0 ? (
          <SearchResult>
            Sök ordernummer, patient (namn eller perssonnummer)
          </SearchResult>
        ) : (
          <SearchResult>
            <Fragment>
              {searchText.length < 4 && `Ange fler än 3 tecken för att söka. `}

              {searched && searchText.length > 3 && (
                <Fragment>
                  <Spacer horizontal={20} />
                  <TextHeader>{`${searchOrders.length} ordrar matchar din sökning`}</TextHeader>
                </Fragment>
              )}
            </Fragment>
          </SearchResult>
        )}
        <div>{error && <div>Error</div>}</div>
      </SearchContainer>
      <Pullable
        onRefresh={() => handleSearch()}
        spinnerColor="#19769f"
        disabled={loading}
      >
        {searchOrders.length > 0
          ? searchOrders.map((order) => (
              <OrderItem key={order.id} order={order} />
            ))
          : searched && (
              <NoOrdersContainer>
                <NoOrdersIcon />
                <NoOrdersText>Inga ordrar hittades</NoOrdersText>
              </NoOrdersContainer>
            )}
      </Pullable>
    </Fragment>
  );
};

export default OrderSearch;
