import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Loader from "react-loader-spinner";

import { AuthContext } from "../../context";
import { BACKEND_URL } from "../../config";
import { Loading, CardExpander } from "../../components";

import {
  Container,
  Card,
  Button,
  Row,
  Column,
  Item,
  TextPrimary,
  TextSecondary,
  Line,
} from "../../styles";

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Image = styled.div`
  flex-grow: 1;
  flex: 0 0 30%;
  margin: 4px;
`;

const File = styled.div`
  flex-grow: 1;
  flex: 0 0 30%;
  margin: 4px;
  padding-bottom: 4px;
  min-height: 50px;
`;

const FileExtension = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.grey};
  background-color: #eee;
  border: 1px solid ${(props) => props.theme.grey};
  border-radius: 3px;
  height: 100%;
`;

const OrderImages = (props) => {
  const { orderId } = props;
  const [images, setImages] = useState();
  const [loading, setLoading] = useState();
  const [uploading, setUploading] = useState();
  const [open, setOpen] = useState(false);
  const { setError } = useContext(AuthContext);

  const fetchImages = async () => {
    if (!images && !loading) {
      setLoading(true);

      try {
        const res = await fetch(`${BACKEND_URL}/attachments/${orderId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        });

        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        const json = await res.json();

        if (json && json.data && json.data.length > 0) {
          setImages(JSON.parse(json.data));
          setLoading(false);
        } else {
          setImages([]);
          setLoading(false);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
        console.log(e.message);
      }
    }
  };

  const handleSelectFile = async (event) => {
    const file = event.target.files[0];
    setUploading(true);

    const imageData = new FormData();
    imageData.append("image", file);

    try {
      const res = await fetch(`${BACKEND_URL}/upload/${orderId}`, {
        method: "POST",
        credentials: "include",
        body: imageData,
      });

      const data = await res.json();

      if (data.status === 200) {
        setUploading(false);
        setImages(false);
        fetchImages();
      } else {
        setUploading(false);
      }
    } catch (err) {
      setError(err.message);
      console.log("error", err.message);
      setUploading(false);
    }
  };

  const handleStartUploadFile = (event) => {
    event.preventDefault();
    fileUpload.click();
  };

  const handleViewImage = (imageId) => {
    if (imageId) props.history.push(`/bild/${imageId}`);
  };

  const handleViewFile = ({ id, ext }) => {
    // Only allow html files for now
    if (id && ext === "html") props.history.push(`/file/${id}`);
  };

  useEffect(() => {
    fetchImages();
  });

  let fileUpload;

  if (!loading && images) {
    return (
      <Container>
        <Card style={{ paddingTop: "0" }}>
          <Row margin="0">
            <Column style={{ margin: "15px" }}>
              <Item style={{ margin: "0" }}>
                <Row
                  style={{
                    margin: "0",
                    alignItems: "center",
                  }}
                >
                  <TextPrimary
                    style={{ flex: 1 }}
                    small
                    onClick={() => setOpen(!open)}
                  >
                    Bilder
                    {images && images.length > 0 ? ` (${images.length})` : null}
                    <CardExpander open={open} />
                  </TextPrimary>
                  <form encType="multipart/form-data">
                    <input
                      type="file"
                      id="file"
                      onChange={handleSelectFile}
                      style={{ display: "none" }}
                      ref={(ref) => (fileUpload = ref)}
                    />
                    {uploading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Loader
                          type="Oval"
                          color="#19769f"
                          height={22}
                          width={22}
                          style={{
                            marginRight: "8px",
                          }}
                        />
                        <TextSecondary>Laddar upp...</TextSecondary>
                      </div>
                    ) : (
                      <Button onClick={handleStartUploadFile} width="120px">
                        Ladda upp
                      </Button>
                    )}
                  </form>
                </Row>
                <div
                  style={{
                    display: open ? "block" : "none",
                    marginTop: "10px",
                  }}
                >
                  <Line />
                  {images && images.length > 0 ? (
                    <Images>
                      {images.map((image) => {
                        if (image.type === "image") {
                          return (
                            <Image
                              key={image.id}
                              onClick={() => handleViewImage(image.id)}
                            >
                              <img
                                src={`data:image/${image.extension};base64, ${image.image}`}
                                alt={image.name}
                                width="100%"
                                key={image.id}
                                style={{ borderRadius: "3px" }}
                              />
                            </Image>
                          );
                        } else {
                          return (
                            <File
                              key={image.id}
                              onClick={() =>
                                handleViewFile({
                                  id: image.id,
                                  ext: image.extension,
                                })
                              }
                            >
                              <FileExtension>.{image.extension}</FileExtension>
                            </File>
                          );
                        }
                      })}
                    </Images>
                  ) : (
                    <TextSecondary>Inga bilder</TextSecondary>
                  )}
                </div>
              </Item>
            </Column>
          </Row>
        </Card>
      </Container>
    );
  } else {
    return (
      <Container>
        <Card>
          <Loading
            containerHeight="150px"
            height={80}
            text="Hämtar bilder..."
          />
        </Card>
      </Container>
    );
  }
};

export default withRouter(OrderImages);
