import React from "react";
import styled from "styled-components";

import { ReactComponent as IconOk } from "../../images/box-ok.svg";
import { ReactComponent as IconFail } from "../../images/box-fail.svg";
import { ReactComponent as IconChevronRight } from "../../images/chevron_right.svg";

const Ok = styled(IconOk)`
  fill: ${(props) => props.theme.green};
  width: 30px;
  height: 30px;
`;

const Fail = styled(IconFail)`
  fill: ${(props) => props.theme.red};
  width: 30px;
  height: 30px;
`;

const ChevronRight = styled(IconChevronRight)`
  fill: ${(props) => props.theme.black};
  width: 30px;
  height: 30px;
`;

const DriverAddressStatusIcon = ({ statusId }) => {
  if (!statusId) return;

  if (statusId < 5) {
    return <></>;
  }

  if (statusId === 20) {
    return <Fail />;
  }

  if (statusId === 30 || statusId === 35) {
    return <Ok />;
  }

  return <ChevronRight />;
};

export default DriverAddressStatusIcon;
