import styled from "styled-components";

const Confirm = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px #ccc solid;
  padding: 15px 40px;

  border-radius: 8px;

  background-color: #19769f;
  color: #ffffff;
`;

export default Confirm;
