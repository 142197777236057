import styled from "styled-components";

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 14px;
`;

export default Line;
