import styled from "styled-components";

const ButtonSecondary = styled.button`
  color: #ffffff;
  border: 1px solid white;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  height: ${props => (props.small ? "24px" : "40px")};
  width: ${props => (props.width ? props.width : "unset")};
  font-size: ${props => (props.small ? "0.8rem" : "1rem")};

  box-shadow: rgba(0, 0, 0, 0.16) 0px 9px 10px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.16) 0px 9px 10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.16) 0px 9px 10px;

  text-decoration: none;
  outline: none;
  display: inline-block;

  background-color: transparent;
`;

export default ButtonSecondary;
