import React from "react";
import { withRouter } from "react-router-dom";

import { Header, OrderList } from "../../components";
import { Page } from "../../styles";

const Home = props => {
  return (
    <Page>
      <Header />
      <OrderList />
    </Page>
  );
};

export default withRouter(Home);
