import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import base64 from "base-64";
import utf8 from "utf8";

import { AuthContext, OrderContext } from "../../context";
import { DropDown, DropDownItem, Button, Label } from "../../styles";

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.grey};
  border-radius: 4px;
`;

const OrderFilterDialog = ({ setFilterMenuOpen }) => {
  const { user } = useContext(AuthContext);
  const {
    filterClinicId,
    setFilterClinicId,
    filterLabId,
    setFilterLabId,
    filterDepartmentId,
    setFilterDepartmentId,
    filterTechnicianId,
    setFilterTechnicianId,
  } = useContext(OrderContext);

  const decodedUser = JSON.parse(utf8.decode(base64.decode(user)));
  const { clinic, lab, department, technicianId } = decodedUser;

  const clearFilters = () => {
    setFilterClinicId("");
    setFilterLabId("");
    setFilterDepartmentId("");
    setFilterTechnicianId("");
    setFilterMenuOpen(false);
  };

  useEffect(() => {
    if (lab && lab.length === 1) {
      setFilterLabId(lab[0].id);
    }
  }, [lab, setFilterLabId]);

  return (
    <FilterContainer>
      {technicianId && (
        <>
          <Label>Visa endast mina ordrar</Label>
          <DropDown
            name="technician"
            id="technician"
            onChange={(e) => setFilterTechnicianId(e.target.value)}
            value={filterTechnicianId}
            disabled={false}
          >
            <DropDownItem value="">Nej</DropDownItem>
            <DropDownItem value={technicianId}>Ja</DropDownItem>
          </DropDown>
        </>
      )}

      {clinic && clinic.length > 1 && (
        <>
          <Label>Klinik</Label>
          <DropDown
            name="clinic"
            id="clinic"
            onChange={(e) => setFilterClinicId(e.target.value)}
            value={filterClinicId}
          >
            <DropDownItem value="">Alla kliniker</DropDownItem>
            {clinic.map((clinicItem) => (
              <DropDownItem key={clinicItem.id} value={clinicItem.id}>
                {clinicItem.name}
              </DropDownItem>
            ))}
          </DropDown>
        </>
      )}
      {lab && lab.length > 1 && (
        <>
          <Label>Labb</Label>
          <DropDown
            name="lab"
            id="lab"
            onChange={(e) => setFilterLabId(e.target.value)}
            value={filterLabId}
          >
            <DropDownItem value="">Alla lab</DropDownItem>
            {lab.map((labItem) => (
              <DropDownItem key={labItem.id} value={labItem.id}>
                {labItem.name}
              </DropDownItem>
            ))}
          </DropDown>
        </>
      )}
      {department && department.length > 0 && (
        <>
          <Label>Avdelning</Label>
          <DropDown
            name="department"
            id="department"
            onChange={(e) => setFilterDepartmentId(e.target.value)}
            value={filterDepartmentId}
            disabled={filterLabId ? false : true}
          >
            <DropDownItem value="">Alla avdelningar</DropDownItem>
            {department.map((departmentItem) => {
              if (filterLabId === departmentItem.idlab) {
                return (
                  <DropDownItem
                    key={departmentItem.id}
                    value={departmentItem.id}
                  >
                    {departmentItem.name}
                  </DropDownItem>
                );
              } else {
                return null;
              }
            })}
          </DropDown>
        </>
      )}
      <Button onClick={clearFilters}>Rensa filter</Button>
    </FilterContainer>
  );
};

export default OrderFilterDialog;
