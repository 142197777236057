import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

import { BACKEND_URL } from "../../config";
import { AuthContext } from "../../context";
import { Loading, CardExpander } from "../../components";
import { fetchData } from "../../utils";

import {
  Container,
  Card,
  Row,
  Column,
  Item,
  TextPrimary,
  Label,
  Text,
  TextHeader,
  Line,
  Spacer,
  Button,
  ButtonSecondary,
  Confirm,
} from "../../styles";

const OrderOperations = React.memo((props) => {
  const { orderId, orderStatusId } = props;
  const { user, setAuthenticated } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const userDecoded = JSON.parse(atob(user));
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery(["operations"], ({ path = `/arbetsmoment/${orderId}` }) =>
    fetchData({ path })
  );
  const operations = data && data.data;

  let isTechnician = false;
  let showActionButtons = false;
  let selectedOperation = "";

  const handleConfirmOperation = async ({ id, action, confirmText }) => {
    if (id && action) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Confirm>
              <TextHeader>{`Vill du ${confirmText}?`}</TextHeader>
              <br />
              <Row style={{ justifyContent: "space-around" }}>
                <ButtonSecondary
                  width="80px"
                  onClick={() => {
                    handleChangeOperation({ id, action });
                    onClose();
                  }}
                >
                  Ja
                </ButtonSecondary>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonSecondary width="80px" onClick={onClose}>
                  Nej
                </ButtonSecondary>
              </Row>
            </Confirm>
          );
        },
      });
    }
  };

  const handleChangeOperation = async ({ id, action }) => {
    if (id && action) {
      setUpdating(true);

      try {
        const res = await fetch(`${BACKEND_URL}/uppdatera-arbetsmoment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ id, action }),
        });

        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        const json = await res.json();

        if (json) {
          if (json.status === 200) {
            props.refetchOrder();
            await refetch();
            setUpdating(false);
          } else {
            setUpdating(false);
          }
        } else {
          setUpdating(false);
        }
      } catch (error) {
        if (
          error.message === "Unauthorized" ||
          Object.keys(error).length === 0
        ) {
          setAuthenticated(false);
        } else {
          setUpdating(false);
          console.log(error.message);
        }
      }
    } else {
      setUpdating(false);
    }
  };

  if (!loading && operations && orderStatusId) {
    if (operations.length > 0) {
      if (userDecoded.roles.indexOf("technician") > -1) isTechnician = true;

      if (
        isTechnician &&
        orderStatusId >= 300 &&
        orderStatusId < 900 &&
        orderStatusId !== 500
      ) {
        showActionButtons = true;
      }

      return (
        <Container>
          <Card>
            <Row margin="0">
              <Column>
                <Item>
                  <TextPrimary onClick={() => setOpen(!open)} small>
                    Arbetsmoment
                    {operations &&
                      operations.length > 0 &&
                      " (" + operations.length + ")"}
                    <CardExpander open={open} />
                  </TextPrimary>
                  <Spacer horizontal={14} />
                  <Line
                    style={{
                      display: open ? "block" : "none",
                    }}
                  />
                </Item>
              </Column>
            </Row>
            <div
              style={{
                display: open ? "block" : "none",
              }}
            >
              {operations.map((operation, index = 0) => {
                if (
                  showActionButtons &&
                  !selectedOperation &&
                  (operation.status === "Planned" ||
                    operation.status === "Started" ||
                    operation.status === "Paused")
                ) {
                  selectedOperation = operation.id;
                }

                return (
                  <div key={operation.id}>
                    <Row>
                      <Column>
                        <Item>
                          <Label>Namn</Label>
                          <Text>
                            {index + 1 + ". "}
                            {operation.name}
                          </Text>
                        </Item>
                      </Column>
                      <Column>
                        <Item
                          style={{
                            justifyContent: "flex-end",
                            flexDirection: "row",
                          }}
                        >
                          {!updating && selectedOperation === operation.id && (
                            <>
                              <Button
                                onClick={() =>
                                  handleConfirmOperation({
                                    id: operation.id,
                                    action: "TASKSTART",
                                    confirmText: "starta",
                                  })
                                }
                                width="30px"
                                disabled={
                                  updating || operation.status === "Started"
                                }
                                style={{
                                  paddingLeft: "2px",
                                  marginRight: "8px",
                                }}
                              >
                                <img
                                  src="/images/play.png"
                                  height="11"
                                  width="11"
                                  alt="Play"
                                />
                              </Button>
                              <Button
                                onClick={() =>
                                  handleConfirmOperation({
                                    id: operation.id,
                                    action: "TASKPAUS",
                                    confirmText: "pausa",
                                  })
                                }
                                width="30px"
                                disabled={
                                  updating ||
                                  operation.status === "Paused" ||
                                  operation.status === "Planned"
                                }
                                style={{ marginRight: "8px" }}
                              >
                                <img
                                  src="/images/pause.png"
                                  height="11"
                                  width="11"
                                  alt="Pause"
                                />
                              </Button>
                              <Button
                                onClick={() =>
                                  handleConfirmOperation({
                                    id: operation.id,
                                    action: "TASKSTOP",
                                    confirmText: "stoppa",
                                  })
                                }
                                width="30px"
                                disabled={
                                  updating ||
                                  operation.status === "Paused" ||
                                  operation.status === "Planned"
                                }
                              >
                                <img
                                  src="/images/stop.png"
                                  height="11"
                                  width="11"
                                  alt="Stop"
                                />
                              </Button>
                            </>
                          )}
                          {updating && selectedOperation === operation.id && (
                            <Loading
                              containerHeight="60px"
                              height={40}
                              text=" "
                            />
                          )}
                        </Item>
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <Item>
                          <Label>Avdelning</Label>
                          <Text>{operation.department}</Text>
                        </Item>
                      </Column>
                      {operation.assignee && (
                        <Column>
                          <Item>
                            <Label>Hanteras av</Label>
                            <Text>{operation.assignee}</Text>
                          </Item>
                        </Column>
                      )}
                    </Row>
                    <Row style={{ marginBottom: 0 }}>
                      <Item>
                        <Line />
                      </Item>
                    </Row>
                  </div>
                );
              })}
            </div>
          </Card>
        </Container>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Container>
        <Card>
          <Loading
            containerHeight="150px"
            height={80}
            text="Hämtar arbetsmoment..."
          />
        </Card>
      </Container>
    );
  }
});

export default withRouter(OrderOperations);
