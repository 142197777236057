import React from "react";
import styled from "styled-components";

import { ReactComponent as FilterIconSVG } from "../../images/filter.svg";
import { ReactComponent as FilterIconSVGClose } from "../../images/filter-close.svg";

const FilterIcon = styled(FilterIconSVG)`
  stroke: ${(props) => props.theme.grey};
  width: 28px;
  height: 28px;
`;

const FilterIconClose = styled(FilterIconSVGClose)`
  stroke: ${(props) => props.theme.grey};
  width: 28px;
  height: 28px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrderFilterIcon = ({ open = false }) => {
  return <Container>{open ? <FilterIconClose /> : <FilterIcon />}</Container>;
};

export default OrderFilterIcon;
