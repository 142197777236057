import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import base64 from "base-64";
import utf8 from "utf8";

import { AuthContext } from "../../context";
import { LoginForm } from "../../components";

const Login = (props) => {
  const { authenticated, user } = useContext(AuthContext);
  let path = "/";

  if (authenticated && user) {
    const userDecoded = JSON.parse(utf8.decode(base64.decode(user)));
    const { roles } = userDecoded;

    if (roles && roles?.length === 1 && roles[0] === "driver") {
      path = "/routes";
    }
  }

  return (
    <div>
      {authenticated ? <Redirect to={path} /> : <LoginForm {...props} />}
    </div>
  );
};

export default Login;
