import React from "react";
import styled from "styled-components";

import { ReactComponent as ArrowUp } from "../../images/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../images/arrow-down.svg";

const Open = styled(ArrowDown)`
  fill: ${(props) => props.theme.blue};
  width: 14px;
  height: 14px;
  padding-top: 4px;
  margin-left: 6px;
`;

const Close = styled(ArrowUp)`
  fill: ${(props) => props.theme.blue};
  width: 14px;
  height: 14px;
  padding-top: 4px;
  margin-left: 6px;
`;

const CardExpander = ({ open }) => {
  if (open) {
    return <Close />;
  } else {
    return <Open />;
  }
};

export default CardExpander;
