import React from "react";
import { withRouter } from "react-router-dom";

import {
  Container,
  Column,
  Row,
  Card,
  Item,
  Text,
  TextPrimary,
  TextSecondary,
  Label,
  TextHeader,
  Spacer
} from "../../styles";

const OrderItem = ({ order, history }) => {
  const handleNavigateToOrder = () => {
    history.push(`/order/${order.orderNumber}`);
  };

  return (
    <Container>
      <Card onClick={handleNavigateToOrder}>
        <Row>
          <Column>
            <Item>
              <Label>Order ({order.type})</Label>
              <TextHeader>{order.orderNumber}</TextHeader>
              {order.status}
            </Item>
            <Item>
              <Label>Pricktid</Label>
              <TextSecondary>{order.dotTime}</TextSecondary>
            </Item>
          </Column>
        </Row>
        <Row>
          <Column>
            <Item>
              <Label>Patient</Label>
              <TextPrimary>
                {order.firstName} {order.lastName}
              </TextPrimary>
              <TextSecondary>
                {order.personId ? order.personId + "-****" : ""}
              </TextSecondary>
            </Item>
            <Item>
              <Label>Tandläkare</Label>
              <Text>{order.dentist}</Text>
            </Item>
          </Column>
        </Row>
        <Row>
          <Column>
            <Item>
              <Label>Klinik</Label>
              <Text>{order.clinic}</Text>
            </Item>

            <Item>
              <Label>Labb</Label>
              <Text>{order.lab}</Text>
            </Item>
          </Column>
        </Row>
        {order.description && (
          <Row>
            <Column>
              <Item>
                <Label>Beskrivning</Label>
                <Text>
                  {order.description.length > 40
                    ? order.description.substring(0, 40) + "..."
                    : order.description}
                </Text>
              </Item>
            </Column>
          </Row>
        )}
        <Spacer horizontal={5} />
      </Card>
    </Container>
  );
};

export default withRouter(OrderItem);
