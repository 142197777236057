import React from "react";
import { withRouter } from "react-router-dom";
import QrReader from "react-qr-reader";

import { Header } from "../../components";
import { Page, Text, TextHeader, Card } from "../../styles";

const Scan = ({ history }) => {
  const handleError = (error) => {
    alert("Error:", error);
  };

  const handleScan = (data) => {
    if (data && data.includes("http") && data.includes("/order/")) {
      let reveresedUrl = data.split("").reverse().join("");
      let endPosition = reveresedUrl.indexOf("/");
      let orderId = reveresedUrl
        .substring(0, endPosition)
        .split("")
        .reverse()
        .join("");

      if (orderId) history.push(`/order/${orderId}`);
    }
  };

  return (
    <Page>
      <Header />
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%", marginBottom: "15px" }}
      />
      <Card style={{ padding: "20px" }}>
        <TextHeader style={{ paddingBottom: "8px" }}>Scanna QR-kod</TextHeader>

        <Text>Håll din QR-kod inom den röda rutan.</Text>
      </Card>
    </Page>
  );
};

export default withRouter(Scan);
