import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../context";

const Error = () => {
  const { authenticated, error } = useContext(AuthContext);

  if (error === "Unauthorized") {
    return <Redirect to="/logout" />;
  } else {
    return authenticated && error ? <div>{error}</div> : null;
  }
};

export default Error;
