import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  Card,
  Row,
  InputNumeric,
  Button,
  Textarea,
  Label,
  Text,
  Checkbox,
} from "../../styles";

import { DriverStopLocation } from "../../components";

import { BACKEND_URL } from "../../config";

const Container = styled.div`
  margin: 14px;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DriverStopItem = ({ history, stop, refetch }) => {
  const [updating, setUpdating] = useState(false);
  const [pickupsActual, setPickupsActual] = useState(stop.pickupsRequested);
  const [droppoffsActual, setDroppoffsActual] = useState(
    stop.droppoffsRequested
  );
  const [comment, setComment] = useState("");
  const [bom, setBom] = useState(false);

  const handleUpdateDriverStop = async () => {
    try {
      setUpdating(true);

      let pickupdiff =
        parseInt(stop?.pickupsRequested) === parseInt(pickupsActual)
          ? false
          : true;
      let droppoffdiff =
        parseInt(stop?.droppoffsRequested) === parseInt(droppoffsActual)
          ? false
          : true;
      let stopdiff = pickupdiff || droppoffdiff ? true : false;
      let stopStatus = bom ? "20" : stopdiff ? "35" : "30";

      let details = `<status>${stopStatus}</status>`;

      if (!bom) {
        details =
          details +
          `<noDropOffs>${droppoffsActual}</noDropOffs><noPickUpps>${pickupsActual}</noPickUpps>`;
      }

      if (comment) {
        details = details + `<comment>${comment}</comment>`;
      }

      const res = await fetch(`${BACKEND_URL}/send-action`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          id: stop.id,
          action: "TRANSPORT-ROUTE-ADDR-UPDATE",
          details,
        }),
      });

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json) {
        if (json.status === 200) {
          await refetch();

          history.goBack();
        }
      }

      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      console.log(error);
    }
  };

  return (
    <Container>
      <Row>
        <Column>
          <h2>{stop.companyName}</h2>
        </Column>
        <Column style={{ alignItems: "flex-end" }}>
          <DriverStopLocation location={stop} />
        </Column>
      </Row>

      <Card style={{ margin: 0, paddingLeft: 14 }}>
        <Row>
          <Column
            style={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Row>
              <Column>
                <Text>Hämta</Text>
              </Column>
            </Row>
            <Row>
              <Column>
                <InputNumeric
                  value={pickupsActual}
                  incValue={() =>
                    setPickupsActual((prevState) => parseInt(prevState) + 1)
                  }
                  decValue={() =>
                    setPickupsActual((prevState) => parseInt(prevState) - 1)
                  }
                  orgValue={stop.pickupsRequested}
                  disabled={parseInt(stop.statusId) !== 10}
                />
              </Column>
            </Row>
          </Column>
          <Column
            style={{ justifyContent: "flex-start", alignItems: "center" }}
          >
            <Row>
              <Column>
                <Text>Lämna</Text>
              </Column>
            </Row>
            <Row>
              <Column>
                <InputNumeric
                  value={droppoffsActual}
                  incValue={() =>
                    setDroppoffsActual((prevState) => parseInt(prevState) + 1)
                  }
                  decValue={() =>
                    setDroppoffsActual((prevState) => parseInt(prevState) - 1)
                  }
                  orgValue={stop.droppoffsRequested}
                  disabled={parseInt(stop.statusId) !== 10}
                />
              </Column>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column>
            <strong>Avsändare:</strong> {stop?.sender}
          </Column>
        </Row>
        {stop?.labComment && (
          <Row>
            <Column>
              <strong>Kommentar från lab:</strong> {stop?.labComment}
            </Column>
          </Row>
        )}
      </Card>
      {stop.statusId === 10 && (
        <div style={{ padding: 0, marginTop: 14 }}>
          <Row>
            <Column>
              <Label style={{ color: "black" }}>Kommentar från förare</Label>
              <Textarea
                value={comment}
                onChange={(e) => setComment(e?.target?.value)}
              />
            </Column>
          </Row>
          <Row>
            <Column style={{ alignItems: "self-start" }}>
              <Label style={{ color: "black" }}>Bom</Label>
              <Checkbox
                type="checkbox"
                checked={bom}
                onChange={() => setBom(() => !bom)}
              />
            </Column>
          </Row>
          <Row>
            <Column>
              <Button
                onClick={() => handleUpdateDriverStop()}
                disabled={updating}
              >
                Klart
              </Button>
            </Column>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default withRouter(DriverStopItem);
