import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../../context";

const AuthRoute = ({ render, ...routeProps }) => {
  const { authenticated } = useContext(AuthContext);

  return (
    <Route
      {...routeProps}
      render={() =>
        authenticated ? (
          render()
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { referrer: routeProps.location.pathname }
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
