import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  height: 50px;
`;

const Tab = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.selected
      ? `border-bottom: 3px ${props.theme.blue} solid`
      : `border-bottom: 1px ${props.theme.grey} solid`}

  ${(props) =>
    props.selected
      ? `color: ${props.theme.blue}`
      : `color: ${props.theme.grey}`}
`;

const OrderTabs = ({ history }) => {
  const { location } = history;

  const tabsData = [
    {
      id: 1,
      label: "Öppna order",
      location: "/",
    },
    {
      id: 2,
      label: "Sök order",
      location: "/search",
    },
  ];

  return (
    <TabsContainer>
      {tabsData.map((tab) => (
        <Tab
          key={tab.id}
          onClick={() => history.push(tab.location)}
          selected={tab.location === location.pathname ? true : false}
        >
          {tab.label}
        </Tab>
      ))}
    </TabsContainer>
  );
};

export default withRouter(OrderTabs);
