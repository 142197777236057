import React, { useState, useContext } from "react";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import LogRocket from "logrocket";

import { AuthContext } from "../../context";
import { BACKEND_URL } from "../../config";
import { version } from "../../../package.json";

import { Form, Button, Input, Spacer } from "../../styles";

const Page = styled.div`
  @media (min-width: 300px) {
    display: flex;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: relative;

  @media (min-width: 300px) {
    width: 300px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  color: #094360;
  font-size: 2.4rem;
`;

const Copy = styled.div`
  color: #094360;
  font-size: 0.8rem;
  margin-left: 10px;

  small {
    color: #95989a;
  }
`;

const ErrorText = styled.div`
  color: #af3030;
  font-size: 1rem;
`;

const LoginForm = props => {
  const { setAuthenticated, setUser } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const referrer = props.location.state && props.location.state.referrer;

  const handleInputChange = e => {
    const { value, name } = e.target;

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleLogin = async e => {
    e.preventDefault();
    setError("");
    const { username, password } = formData;

    if (username && password) {
      setLoading(true);

      try {
        const response = await fetch(`${BACKEND_URL}/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({ username, password })
        });

        const result = await response.json();

        if (result && result.status === 200) {
          if (result.user) {
            LogRocket.identify(username, {
              name: result.name,
              email: username
            });
            setUser(result.user);
          }

          setLoading(false);
          if (referrer && referrer !== "/logout") {
            setAuthenticated(true);
            props.history.push(referrer);
          } else {
            setAuthenticated(true);
          }
        } else {
          setLoading(false);
          setError("Felaktigt användarnamn och eller lösenord");
        }
      } catch (e) {
        setLoading(false);
        setError("Kunde inte ansluta, vänligen testa igen");
      }
    }
  };

  return (
    <Page>
      <Container>
        <Spacer horizontal={80} />
        <Logo>
          <img src="/images/logo.png" width="80" height="80" alt="logo" />
        </Logo>
        <Form>
          <Header>
            Tandis
            <Copy>
              <small>by</small> Blå Tornet <small>v.{version}</small>
            </Copy>
          </Header>
          <Spacer horizontal={38} />
          <Input
            type="text"
            placeholder="Användarnamn"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            autoComplete="current-username"
            disabled={loading}
            autoCapitalize="none"
          />
          <Spacer horizontal={28} />
          <Input
            type="password"
            placeholder="Lösenord"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            autoComplete="current-password"
            disabled={loading}
            autoCapitalize="none"
          />
          {error && (
            <>
              <Spacer horizontal={28} />
              <ErrorText>{error}</ErrorText>
            </>
          )}
          <Spacer horizontal={28} />
          <Button type="submit" onClick={handleLogin} disabled={loading}>
            {loading && (
              <Loader
                type="Oval"
                color="#ffffff"
                height={14}
                width={14}
                style={{ display: "inline-block", marginRight: "10px" }}
              />
            )}
            {loading ? "Loggar in..." : "Logga in"}
          </Button>
        </Form>
      </Container>
    </Page>
  );
};

export default LoginForm;
