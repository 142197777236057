import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { BACKEND_URL } from "../../config";

import { Loading, Header, NotFound } from "../../components";
import { Page, Container } from "../../styles";

const File = (props) => {
  const { fileId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();

  useEffect(() => {
    const fetchRequest = new AbortController();
    const signal = fetchRequest.signal;
    let isCanceled = false;

    const fetchFile = async () => {
      try {
        const res = await fetch(`${BACKEND_URL}/attachment/${fileId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          signal,
        });

        const json = await res.json();

        if (json && json.data) {
          if (!isCanceled) {
            setFile(JSON.parse(json.data));
            setLoading(false);
          }
        } else {
          if (!isCanceled) setLoading(false);
        }
      } catch (e) {
        if (!isCanceled) setLoading(false);
        console.log(e);
      }
    };

    fetchFile();

    return () => {
      if (fetchRequest !== undefined) {
        isCanceled = true;
        fetchRequest.abort();
      }
    };
  }, [fileId]);

  if (loading) {
    return (
      <Page>
        <Header title="Fil" />
        <Container>
          <Loading text="Hämtar fil..." />
        </Container>
      </Page>
    );
  }

  return (
    <Page>
      <Header title="Fil" />
      {file ? (
        <iframe
          src={`data:text/${file.extension};base64, ${file.image}`}
          title={file.name}
          width="100%"
          key={file.id}
          frameBorder="0"
          style={{ height: "100vh" }}
        />
      ) : (
        <Container>
          <NotFound text="Hoppsan, filen hittades inte!" />{" "}
        </Container>
      )}
    </Page>
  );
};

export default withRouter(File);
