import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import App from "./App";
//import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === "production") {
  LogRocket.init("07wx1n/tandis-client");
}

ReactDOM.render(<App />, document.getElementById("root"));
//serviceWorker.unregister();
