import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import { debounce } from "throttle-debounce";
import base64 from "base-64";
import utf8 from "utf8";

import { AuthContext, OrderContext } from "../../context";

import {
  SearchInput,
  OrderFilterIcon,
  OrderFilterDialog,
} from "../../components";

import { TextHeader } from "../../styles";

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
`;

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterResult = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.grey};
  margin-top: 24px;
  margin-left: 4px;
`;

const FilterButton = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 10px;
`;

const FilterItem = styled.span`
  color: ${(props) => props.theme.grey};
  font-size: 14px;
  margin: 4px;
`;

const OrderFilter = ({ filterMenuOpen, setFilterMenuOpen }) => {
  const { user } = useContext(AuthContext);
  const {
    filterOrders,
    ordersDefault,
    orders,
    filterText,
    setFilterText,
    filterTechnicianId,
    filterClinicId,
    filterDepartmentId,
  } = useContext(OrderContext);

  const decodedUser = JSON.parse(utf8.decode(base64.decode(user)));
  const { clinic, department } = decodedUser;

  const handleSetFilter = (e) => {
    const { value } = e.target;
    setFilterText(value);

    //Only Filter on words longer than 2 charcters
    if (value && value.length > 2) {
      filterOrdersDebounced(value);
    }
  };

  //Use Debounceing to only call Filter with a delay
  const filterOrdersDebounced = useCallback(
    debounce(500, (value) => {
      filterOrders(value);
    }),
    []
  );

  const handleClearFilter = () => {
    setFilterText("");
    filterOrders("");
  };

  return (
    <FilterContainer>
      <FilterBar>
        <SearchInput
          type="text"
          placeholder="Fyll i ord att filtrera..."
          name="filter"
          value={filterText}
          onChange={handleSetFilter}
          onClear={handleClearFilter}
          autoComplete="current-search"
          style={{ flex: 1 }}
        />
        <FilterButton onClick={() => setFilterMenuOpen(!filterMenuOpen)}>
          <OrderFilterIcon open={filterMenuOpen} />
        </FilterButton>
      </FilterBar>
      <div style={{ display: "flex" }}>
        {!filterMenuOpen && filterClinicId && (
          <FilterItem>
            <strong>Klinik: </strong>
            {clinic.filter((item) => item.id === filterClinicId)[0].name}
          </FilterItem>
        )}
        {!filterMenuOpen && filterDepartmentId && (
          <FilterItem>
            <strong>Avdelning: </strong>
            {
              department.filter((item) => item.id === filterDepartmentId)[0]
                .name
            }
          </FilterItem>
        )}
        {!filterMenuOpen && filterTechnicianId && (
          <FilterItem>
            <strong>Endast mina:</strong> Ja
          </FilterItem>
        )}
      </div>
      {filterMenuOpen ? (
        <OrderFilterDialog setFilterMenuOpen={setFilterMenuOpen} />
      ) : (
        <>
          {filterText && filterText.length > 0 ? (
            <FilterResult>
              {orders && orders.length >= 0 && (
                <TextHeader>{`${orders.length} ordrar matchar din filtrering`}</TextHeader>
              )}
            </FilterResult>
          ) : (
            <FilterResult>
              {ordersDefault && ordersDefault.length > 0 && (
                <TextHeader>{`Du har ${ordersDefault.length} öppna order`}</TextHeader>
              )}
            </FilterResult>
          )}
        </>
      )}
    </FilterContainer>
  );
};

export default OrderFilter;
