import styled from "styled-components";

const Link = styled.button`
  color: ${(props) => props.theme.blue};
  margin: 0;
  padding: 0;
  border: none;

  text-decoration: underline;
  outline: none;
  display: inline-block;

  background-color: transparent;
`;

export default Link;
