import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { AppContext } from "./context";

import { AuthRoute, Error } from "./components";
import { GlobalStyle, theme } from "./theme";
import { Container } from "./styles";

import {
  Login,
  Logout,
  Menu,
  Home,
  Order,
  Image,
  File,
  FourOFour,
  Scan,
  Search,
  DriverRoutes,
  DriverRoute,
  DriverStop
} from "./pages";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContext>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Container>
              <Error />
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={(props) => <Login {...props} />}
                />
                <AuthRoute exact path="/menu" render={() => <Menu />} />
                <AuthRoute exact path="/" render={() => <Home />} />
                <AuthRoute
                  exact
                  path="/order/:orderNumber"
                  render={() => <Order />}
                />
                <AuthRoute
                  exact
                  path="/bild/:imageId"
                  render={() => <Image />}
                />
                <AuthRoute exact path="/file/:fileId" render={() => <File />} />
                <AuthRoute exact path="/search" render={() => <Search />} />
                <AuthRoute exact path="/scan" render={() => <Scan />} />
                <AuthRoute exact path="/routes" render={() => <DriverRoutes />} />
                <AuthRoute exact path="/route/:routeId" render={() => <DriverRoute />} />
                <AuthRoute exact path="/stop/:stopId" render={() => <DriverStop />} />
                <AuthRoute exact path="/logout" render={() => <Logout />} />
                <AuthRoute render={() => <FourOFour />} />
              </Switch>
            </Container>
            <GlobalStyle />
          </BrowserRouter>
        </ThemeProvider>
      </AppContext>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
