import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";

import { OrderTabs, OrderFilter, OrderItem } from "../../components";
import { LoadingContainer, Button } from "../../styles";
import { OrderContext } from "../../context";

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 14px;
  padding: 3rem;
`;

/*const FilterText = styled.div`
  margin-top: 1rem;
  color: ${(props) => props.theme.grey};
  font-size: 1.25rem;
`;*/

const RefreshContainer = styled.div`
  color: ${(props) => props.theme.grey};
  font-size: 0.8rem;
  text-align: center;
`;

const OrderList = ({ history }) => {
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const {
    orders,
    loadingOrders,
    fetchDefaultOrders,
    fetchMoreOrders,
    ordersHasMore,
    filterText,
  } = useContext(OrderContext);

  const refreshOrders = () => {
    fetchDefaultOrders();
  };

  if (loadingOrders)
    return (
      <LoadingContainer>
        <Loader type="Oval" color="#19769f" height={140} width={140} />
        <br />
        Hämtar ordrar...
      </LoadingContainer>
    );

  return (
    <div>
      <OrderTabs />
      <OrderFilter
        filterMenuOpen={filterMenuOpen}
        setFilterMenuOpen={setFilterMenuOpen}
      />
      {
        //!filterMenuOpen && (
        <InfiniteScroll
          dataLength={(orders && orders.length) || 0}
          next={fetchMoreOrders}
          hasMore={ordersHasMore}
          loader={<h4>Loading...</h4>}
          endMessage={<p style={{ marginBottom: 30 }}></p>}
          refreshFunction={refreshOrders}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={
            <RefreshContainer>
              &#8595; Dra ner för att uppdatera
            </RefreshContainer>
          }
          releaseToRefreshContent={
            <RefreshContainer>&#8593; Släpp för att uppdatera</RefreshContainer>
          }
        >
          {orders && orders.length > 0 ? (
            orders.map((order) => <OrderItem key={order.id} order={order} />)
          ) : (
            <FilterContainer>
              {/*<FilterText>Inga ordrar hittades...</FilterText>
            <br />*/}
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/search",
                    state: { searchFor: filterText },
                  })
                }
                width="270px"
              >
                Sök gamla eller stängda ordrar?
              </Button>
            </FilterContainer>
          )}
        </InfiniteScroll>
        // )
      }
    </div>
  );
};

export default withRouter(OrderList);
