import React, { useState } from "react";

import { useFetch } from "../../utils";
import { Loading, CardExpander } from "../../components";

import {
  Container,
  Card,
  Row,
  Column,
  Item,
  TextPrimary,
  TextSecondary,
  Label,
  Text,
  Line,
  Spacer,
} from "../../styles";

const OrderActions = React.memo((props) => {
  const { orderId } = props;
  const [{ data, loading }] = useFetch({ path: `/atgard/${orderId}` });
  const [open, setOpen] = useState(true);

  if (!loading && data) {
    return (
      <Container>
        <Card>
          <Row margin="0">
            <Column>
              <Item>
                <TextPrimary onClick={() => setOpen(!open)} small>
                  Åtgärder {data && data.length > 0 && "(" + data.length + ")"}
                  <CardExpander open={open} />
                </TextPrimary>

                <Spacer horizontal={14} />
                <Line
                  style={{
                    display: open ? "block" : "none",
                  }}
                />
              </Item>
            </Column>
          </Row>
          <div
            style={{
              display: open ? "block" : "none",
            }}
          >
            {data && data.length > 0 ? (
              data.map((service, index) => (
                <Item key={service.id}>
                  <Row>
                    <Column>
                      <Item style={{ margin: 0 }}>
                        <TextSecondary>
                          {index + 1}.&nbsp;{service.name}
                        </TextSecondary>
                        <Label>{service.description}</Label>
                      </Item>
                    </Column>
                    <Item
                      style={{
                        flex: "unset",
                        display: "flex",
                        alignItems: "flex-end",
                        marginRight: 0,
                      }}
                    >
                      <Text>
                        {service.amount + " "}
                        {service.unit}
                      </Text>
                      <Label>{service.hours + " timmar"}</Label>
                    </Item>
                  </Row>
                  <Line />
                </Item>
              ))
            ) : (
              <Row>
                <Column>
                  <Item>
                    <TextSecondary>Inga åtgärder hittades</TextSecondary>
                  </Item>
                </Column>
              </Row>
            )}
          </div>
        </Card>
      </Container>
    );
  } else {
    return (
      <Container>
        <Card>
          <Loading
            containerHeight="150px"
            height={80}
            text="Hämtar åtgärder..."
          />
        </Card>
      </Container>
    );
  }
});

export default OrderActions;
