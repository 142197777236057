import React, { useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { useQuery } from "react-query";

import { Header, Loading, DriverStopItem } from "../../components";
import { Page, Button } from "../../styles";
import { fetchData } from "../../utils";

import { BACKEND_URL } from "../../config";

const DriverStop = (props) => {
  const [updating, setUpdating] = useState(false);
  let { stopId } = props.match.params;

  const {
    data: stop,
    isLoading: loading,
    refetch,
  } = useQuery(
    ["route-stop", stopId],
    ({
      path = `/route-address`,
      body = JSON.stringify({ addressId: stopId }),
    }) => fetchData({ path, body })
  );

  const handleArrived = async () => {
    try {
      setUpdating(true);

      const res = await fetch(`${BACKEND_URL}/send-action`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          id: stopId,
          action: "TRANSPORT-ROUTE-ADDR-UPDATE",
          details: "<status>10</status>",
        }),
      });

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json) {
        if (json.status === 200) {
          await refetch();
        }
      }

      setUpdating(false);
    } catch (error) {
      setUpdating(false);
      console.log(error);
    }
  };

  if (stop && stop?.status && stop?.status === 401) {
    return <Redirect to="/logout" />;
  }

  return (
    <Page>
      <Header />
      {loading && <Loading />}
      {!loading && stop && !stop?.data && <>Inget stopp hittades</>}
      {!loading && stop && stop?.data && (
        <>
          <DriverStopItem stop={stop.data} refetch={refetch} />
          {stop.data.statusId === 5 && (
            <Button
              onClick={() => handleArrived()}
              style={{ marginTop: 0, marginLeft: 14, marginRight: 14 }}
              disabled={updating}
            >
              Framme
            </Button>
          )}
        </>
      )}
    </Page>
  );
};

export default withRouter(DriverStop);
