import React from "react";
import Loader from "react-loader-spinner";

import { LoadingContainer } from "../../styles";

const Loading = ({
  containerHeight = "80vh",
  height = 140,
  width = 140,
  text = "Laddar..."
}) => (
  <LoadingContainer height={containerHeight}>
    <Loader type="Oval" color="#19769f" height={height} width={width} />
    <br />
    {text}
  </LoadingContainer>
);

export default Loading;
