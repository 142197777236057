import React from "react";
import { withRouter } from "react-router-dom";

import { Header, OrderSearch } from "../../components";
import { Page } from "../../styles";

const Search = ({ location }) => {
  let searchFor;

  if (location && location.state && location.state.searchFor) {
    searchFor = location.state.searchFor;
  }

  return (
    <Page>
      <Header />
      <OrderSearch searchFor={searchFor} />
    </Page>
  );
};

export default withRouter(Search);
