import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import { ReactComponent as BackSVG } from "../../images/back.svg";

const Container = styled.div`
  margin-bottom: 56px;
`;

const BackIcon = styled(BackSVG)`
  fill: ${(props) => props.theme.white};
  width: 20px;
  height: 20px;
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;

  color: ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.blue};
`;

const HeaderLeft = styled.div`
  display: flex;
  padding-left: 1rem;
`;

const HeaderCenter = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const HeaderRight = styled.div`
  display: flex;
  padding-right: 1rem;
`;

const HeaderTitle = styled.div`
  font-size: 1.5rem;
`;

const Hamburger = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const Line = styled.div`
  width: 22px;
  height: 3px;
  background-color: ${(props) => props.theme.white};
  border-radius: 3px;
  margin: 2px 0;
`;

const ElevationScroll = (props) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

const Header = (props) => {
  const {
    history,
    title,
    match: { path },
  } = props;
  return (
    <Container>
      <ElevationScroll {...props}>
        <AppBar>
          <HeaderContainer>
            <HeaderLeft>
              {path === "/" ||
              path === "/menu" ||
              path === "/search" ||
              path === "/routes" ? (
                <img src="/images/logo.png" width="40" height="40" alt="logo" />
              ) : (
                <BackIcon onClick={() => history.goBack()} />
              )}
            </HeaderLeft>
            <HeaderCenter>
              {title ? (
                <HeaderTitle>{title}</HeaderTitle>
              ) : (
                <HeaderTitle>Tandis</HeaderTitle>
              )}
            </HeaderCenter>
            <HeaderRight>
              {path === "/menu" ? (
                <Hamburger onClick={() => history.goBack()}>
                  <img
                    src="/images/close.png"
                    alt="close"
                    height="18"
                    width="18"
                  />
                </Hamburger>
              ) : (
                <Hamburger onClick={() => history.push("/menu")}>
                  <Line />
                  <Line />
                  <Line />
                </Hamburger>
              )}
            </HeaderRight>
          </HeaderContainer>
        </AppBar>
      </ElevationScroll>
    </Container>
  );
};

export default withRouter(Header);
