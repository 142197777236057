import React, { useState } from "react";
import styled from "styled-components";

import { Loading, CardExpander } from "../../components";
import { useFetch } from "../../utils";

import {
  Container,
  Card,
  Row,
  Column,
  Item,
  TextPrimary,
  Line,
} from "../../styles";

import { ReactComponent as BoxSVG } from "../../images/box.svg";
import { ReactComponent as PatientSVG } from "../../images/patient.svg";
import { ReactComponent as DocumentSVG } from "../../images/document.svg";

const BoxIcon = styled(BoxSVG)`
  fill: ${(props) => props.theme.white};
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.3rem;
  margin-top: 0rem;
`;

const PatientIcon = styled(PatientSVG)`
  fill: ${(props) => props.theme.white};
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
`;

const DocumentIcon = styled(DocumentSVG)`
  fill: ${(props) => props.theme.white};
  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;
`;

const PrintItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const PrintItem = styled.a`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 0 0 20%;
  margin: 4px;
  background-color: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};

  text-decoration: none;
  padding: 1rem 0rem;
  border-radius: 0.3rem;

  justify-content: center;
  align-items: center;
`;

const OrderPrint = (props) => {
  const { orderId } = props;
  const [{ data: printSettings, loading }] = useFetch({
    path: `/settingsprint`,
  });
  const [open, setOpen] = useState(false);

  if (!loading && printSettings) {
    return (
      <Container>
        <Card style={{ paddingTop: "0" }}>
          <Row margin="0">
            <Column style={{ margin: "15px" }}>
              <Item style={{ margin: "0" }}>
                <Row
                  style={{
                    margin: "0",
                    alignItems: "center",
                  }}
                >
                  <TextPrimary
                    style={{ flex: 1 }}
                    small
                    onClick={() => setOpen(!open)}
                  >
                    Skriv ut
                    <CardExpander open={open} />
                  </TextPrimary>
                </Row>
                <div
                  style={{
                    display: open ? "block" : "none",
                    marginTop: "10px",
                  }}
                >
                  <Line />
                  <PrintItemContainer>
                    {printSettings &&
                      printSettings.map((item) => {
                        return (
                          <PrintItem
                            key={item.id}
                            href={`${item.value.replace(
                              "<IDORDER/>",
                              orderId
                            )}`}
                            target="_blank"
                          >
                            {item.name.indexOf("PrintSticker") > -1 && (
                              <BoxIcon />
                            )}
                            {item.name.indexOf("PrintSpecification") > -1 && (
                              <DocumentIcon />
                            )}
                            {item.name.indexOf("PrintPatientSpecification") >
                              -1 && <PatientIcon />}
                            <p style={{ padding: "0 1rem" }}>{item.label}</p>
                          </PrintItem>
                        );
                      })}
                  </PrintItemContainer>
                </div>
              </Item>
            </Column>
          </Row>
        </Card>
      </Container>
    );
  } else {
    return (
      <Container>
        <Card>
          <Loading
            containerHeight="150px"
            height={80}
            text="Hämtar inställningar..."
          />
        </Card>
      </Container>
    );
  }
};

export default OrderPrint;
