import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import base64 from "base-64";
import utf8 from "utf8";

import { Header } from "../../components";
import { Page, Spacer } from "../../styles";

import { version } from "../../../package.json";
import { AuthContext } from "../../context";

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #fff;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 95vh;
  background-color: #19769f;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  height: 20px;
`;

const MenuItemText = styled.div`
  font-size: 1.25rem;
  color: white;
  border-bottom: 2px white solid;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3rem;
`;

const Version = styled.div`
  color: white;
  font-size: 0.8rem;
`;

const Update = styled.div`
  color: ${(props) => props.theme.lightgrey};
  font-size: 0.8rem;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`;

const ProfileImage = styled.div`
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 1000px;
  -khtml-border-radius: 1000px;
  border: 2px solid ${(props) => props.theme.white};
  background-color: ${(props) => props.theme.lightgrey};
  height: 150px;
  width: 150px;
  margin: 1rem 0;
  padding: 0;
`;

const ProfileInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.white};
  font-size: 2rem;
  height: 100%;
  text-align: center;
`;

const ProfileName = styled.div`
  color: ${(props) => props.theme.white};
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

const ProfileRoles = styled.div`
  color: ${(props) => props.theme.lightgrey};
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Menu = (props) => {
  const { user } = useContext(AuthContext);
  const userDecoded = JSON.parse(utf8.decode(base64.decode(user)));

  const checkRole = ({ role, roles }) => {
    return roles.toString().includes(role);
  };

  return (
    <Page>
      <Header title="Meny" />
      <Line />
      <MenuWrapper>
        <Profile>
          <ProfileImage>
            <ProfileInitials>
              {userDecoded.name
                .split(" ")
                .map((n) => n[0])
                .join(".")}
            </ProfileInitials>
          </ProfileImage>
          <ProfileName>{userDecoded.name}</ProfileName>
          <ProfileRoles>
            {userDecoded &&
              userDecoded.roles &&
              userDecoded.roles.map((role) => (
                <div key={role}>
                  {role
                    .replace("technician", "Tandtekniker")
                    .replace("dentist", "Tandläkare")
                    .replace("driver", "Förare")
                    .replace("admin", "Administratör")}
                </div>
              ))}
          </ProfileRoles>
        </Profile>
        <Line />
        {(checkRole({ role: "technician", roles: userDecoded.roles }) ||
          checkRole({ role: "dentist", roles: userDecoded.roles })) && (
            <>
              <MenuItem onClick={() => props.history.push("/")}>
                <MenuItemText>Öppna order</MenuItemText>
              </MenuItem>
              <MenuItem onClick={() => props.history.push("/search")}>
                <MenuItemText>Sök order</MenuItemText>
              </MenuItem>
              <MenuItem onClick={() => props.history.push("/scan")}>
                <MenuItemText>Skanna QR-kod</MenuItemText>
              </MenuItem>
            </>
          )}
        {checkRole({ role: "driver", roles: userDecoded.roles }) &&
          userDecoded.driverId && (
            <MenuItem onClick={() => props.history.push("/routes")}>
              <MenuItemText>Körorder</MenuItemText>
            </MenuItem>
          )}
        <MenuItem onClick={() => props.history.push("/logout")}>
          <MenuItemText>Logga ut</MenuItemText>
        </MenuItem>
        <Spacer horizontal="50" />
        <Line />
        <Bottom>
          <Version>version {version}</Version>
          <br />
          <Update onClick={() => (window.location = "/")}>
            Sök uppdatering?
          </Update>
        </Bottom>
      </MenuWrapper>
    </Page>
  );
};

export default withRouter(Menu);
