import styled from "styled-components";

const DropDown = styled.select`
  color: ${(props) => props.theme.darkBlue};
  border: 1px ${(props) => props.theme.lightgrey} solid;
  border-radius: 4px;
  padding: 15px;
  font-size: 1rem;
  outline: none;
  background-color: #fafafa;

  &:focus {
    border: 1px #d5d5d5 solid;
  }

  &:disabled {
    color: ${(props) => props.theme.lightgrey};
    background-color: ${(props) => props.theme.lightwhite};
    border: 1px ${(props) => props.theme.lightgrey} solid;
  }

  margin-bottom: 1rem;

  option {
    background-color: yellow;
  }
`;

export default DropDown;
