import styled from "styled-components";

const Card = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px #ccc solid;
  margin: 7px 15px;
  padding-top: 15px;

  border-radius: 4px;
`;

export default Card;
