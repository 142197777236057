import React from "react";
import styled from "styled-components";

import { Input } from "../../styles";

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const ClearInputIcon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 8px;
  right: 8px;

  height: 24px;
  border-radius: 12px;
  color: ${(props) => props.theme.grey};
  opacity: 0.9;
  font-size: 12px;
`;

const SearchInput = ({ placeholder, name, value, onChange, onClear }) => {
  return (
    <InputContainer>
      <Input
        type="text"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        autoComplete="current-search"
        style={{ flex: 1 }}
      />
      {onClear && value && value.length > 0 && (
        <ClearInputIcon onClick={(e) => onClear(e)}>rensa</ClearInputIcon>
      )}
    </InputContainer>
  );
};

export default SearchInput;
