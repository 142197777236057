import React, { useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import { confirmAlert } from "react-confirm-alert";

import { Header, Loading, DriverAddressStatusIcon } from "../../components";
import {
  Page,
  Card,
  Button,
  ButtonSecondary,
  Confirm,
  Row,
  TextHeader,
  Column,
} from "../../styles";
import { fetchData } from "../../utils";
import { BACKEND_URL } from "../../config";

const DriverRoute = ({ history, match }) => {
  const [routeStarted, setRouteStarted] = useState(true);
  const [updating, setUpdating] = useState(false);
  let { routeId } = match.params;

  const handleConfirmRouteStart = ({ id, action, confirmText }) => {
    if (id && action) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Confirm>
              <TextHeader>{`Vill du ${confirmText}?`}</TextHeader>
              <br />
              <Row style={{ justifyContent: "space-around" }}>
                <ButtonSecondary
                  width="80px"
                  onClick={() => {
                    handleRouteStart({ id, action });
                    onClose();
                  }}
                >
                  Ja
                </ButtonSecondary>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonSecondary width="80px" onClick={onClose}>
                  Nej
                </ButtonSecondary>
              </Row>
            </Confirm>
          );
        },
      });
    }
  };

  const handleRouteStart = async ({ id, action }) => {
    if (!id || !action) return;

    try {
      setUpdating(true);

      const res = await fetch(`${BACKEND_URL}/send-action`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ id, action }),
      });

      if (res.status !== 200) {
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json) {
        if (json.status === 200) {
          await refetch();
          setUpdating(false);
          setRouteStarted(true);
        } else {
          setUpdating(false);
        }
      } else {
        setUpdating(false);
      }
    } catch (error) {
      setUpdating(false);
      console.log(error.message);
    }
  };

  const {
    data: routeAddresses,
    isLoading: loading,
    refetch,
  } = useQuery(
    ["route-addresses", routeId],
    ({ path = `/route-addresses`, body = JSON.stringify({ routeId }) }) =>
      fetchData({ path, body })
  );

  const handleClickStop = ({ id, statusId }) => {
    if (!statusId) return;

    if (parseInt(statusId) === 5 || parseInt(statusId) === 10)
      history.push(`/stop/${id}`);
  };

  if (
    routeAddresses &&
    routeAddresses?.status &&
    routeAddresses?.status === 401
  ) {
    return <Redirect to="/logout" />;
  }

  return (
    <Page>
      <Header />
      {loading && <Loading />}
      {!loading && routeAddresses && routeAddresses?.data?.length === 0 && (
        <>Det finns inga stopp för denna rutt</>
      )}
      {!loading && routeAddresses && routeAddresses?.data?.length > 0 && (
        <>
          <h2 style={{ marginLeft: 14, marginTop: 10 }}>Mina stop</h2>
          {routeAddresses.data.map(
            ({
              id,
              address,
              city,
              companyName,
              pickupsRequested,
              droppoffsRequested,
              status,
              statusId,
              labComment,
            }) => {
              if (routeStarted) {
                if (statusId <= 4) setRouteStarted(false);
              }

              return (
                <Card
                  key={id}
                  style={{
                    padding: 14,
                  }}
                  onClick={() => handleClickStop({ id, statusId })}
                >
                  <Row style={{ margin: 0 }}>
                    <Column style={{ flex: 5, flexDirection: "column" }}>
                      <Column style={{ flexDirection: "column" }}>
                        <strong>
                          {city} - {address}
                        </strong>
                        {companyName}
                      </Column>
                      <Column
                        style={{ marginTop: 10, flexDirection: "column" }}
                      >
                        {`${pickupsRequested} hämtning${
                          pickupsRequested === "1" ? "" : "ar"
                        } och ${droppoffsRequested} lämning${
                          droppoffsRequested === "1" ? "" : "ar"
                        }`}
                      </Column>
                      <Column
                        style={{
                          marginTop: 10,
                          flexDirection: "row",
                          color: "red",
                        }}
                      >
                        {labComment}
                      </Column>
                    </Column>
                    <Column style={{ justifyContent: "flex-end" }}>
                      {statusId > 3 && (
                        <DriverAddressStatusIcon statusId={statusId} />
                      )}
                    </Column>
                  </Row>
                  {status === "Sorterad" && <></>}
                </Card>
              );
            }
          )}
          {!routeStarted && (
            <Button
              onClick={() =>
                handleConfirmRouteStart({
                  id: routeId,
                  action: "TRANSPORT-ROUTE-START",
                  confirmText: "starta rutten",
                })
              }
              style={{ margin: 14 }}
              disabled={updating}
            >
              {updating ? "Startar..." : "Starta rutt"}
            </Button>
          )}
        </>
      )}
    </Page>
  );
};

export default withRouter(DriverRoute);
