import React, { useContext } from "react";
import styled from "styled-components";
import { withRouter, Redirect } from "react-router-dom";
import { useQuery } from "react-query";
import base64 from "base-64";
import utf8 from "utf8";
import { isToday } from "date-fns";

import { Header, Loading, DriverRouteStatusIcon } from "../../components";
import { Page, Card, Row, Column } from "../../styles";
import { fetchData } from "../../utils";

import { AuthContext } from "../../context";

import { ReactComponent as IconRoad } from "../../images/road.svg";

const RoadIcon = styled(IconRoad)`
  fill: ${(props) => props.theme.grey};
  width: 100px;
  height: 100px;
`;

const DriverRoutes = ({ history }) => {
  const { user } = useContext(AuthContext);
  const userDecoded = JSON.parse(utf8.decode(base64.decode(user)));
  const driverId = userDecoded.driverId;

  let routesToday = [];
  let routesPrev = [];

  const { data: routes, isLoading: loading } = useQuery(
    ["routes"],
    ({ path = `/routes`, body = JSON.stringify({ driverId }) }) =>
      fetchData({ path, body })
  );

  if (!loading && routes && routes?.data?.length > 0) {
    routes.data.map((route) => {
      if (isToday(new Date(route.transportDate))) {
        routesToday.push(route);
      } else {
        routesPrev.push(route);
      }

      return null;
    });
  }

  const handleClickRoute = ({ id, statusId }) => {
    if (!statusId) return;

    if (parseInt(statusId) < 50) history.push(`/route/${id}`);
  };

  if (routes && routes?.status && routes?.status === 401) {
    return <Redirect to="/logout" />;
  }

  return (
    <Page>
      <Header />
      {loading && <Loading />}
      {!loading && routesToday && routesToday?.length === 0 && (
        <div style={{ marginLeft: 14, marginTop: 10 }}>
          <h2>Dagens rutter</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "grey",
              marginTop: 50,
            }}
          >
            <div>
              <RoadIcon />
            </div>
            <div>Det finns inga rutter för dig idag...</div>
          </div>
        </div>
      )}
      {!loading && routesToday && routesToday?.length > 0 && (
        <>
          <h2 style={{ marginLeft: 14, marginTop: 10 }}>Dagens rutter</h2>
          {routesToday.map(
            ({
              id,
              routeNumber,
              routeType,
              pickupsRequested,
              droppoffsRequested,
              transportDate,
              statusId,
            }) => {
              return (
                <Card key={id}>
                  <Row
                    style={{ marginBottom: 0, marginLeft: 10, marginRight: 10 }}
                  >
                    <Column
                      style={{
                        flex: 9,
                        flexDirection: "column",
                      }}
                      onClick={() => handleClickRoute({ id, statusId })}
                    >
                      <Row>
                        <strong>
                          {transportDate?.substr(0, 10)} - {routeNumber} -{" "}
                          {routeType}
                        </strong>
                      </Row>
                      <Row>
                        {`${pickupsRequested} hämtning${
                          pickupsRequested === "1" ? "" : "ar"
                        } och ${droppoffsRequested} lämning${
                          droppoffsRequested === "1" ? "" : "ar"
                        }`}
                      </Row>
                    </Column>
                    <Column style={{ justifyContent: "flex-end" }}>
                      <DriverRouteStatusIcon statusId={statusId} />
                    </Column>
                  </Row>
                </Card>
              );
            }
          )}
        </>
      )}
      {/*!loading && routesPrev && routesPrev?.length === 0 && (
        <div style={{ marginLeft: 14, marginTop: 10 }}>
          <h2>Tidigare rutter</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "grey",
              marginTop: 50,
            }}
          >
            <div>
              <RoadIcon />
            </div>
            <div>Det finns inga tidigare rutter...</div>
          </div>
        </div>
      )*/}

      {!loading && routesPrev && routesPrev?.length > 0 && (
        <>
          <h2 style={{ marginLeft: 14, marginTop: 64 }}>Tidigare rutter</h2>
          {routesPrev.map(
            ({
              id,
              routeNumber,
              routeType,
              pickupsRequested,
              droppoffsRequested,
              transportDate,
              statusId,
            }) => {
              return (
                <Card key={id}>
                  <Row
                    style={{ marginBottom: 0, marginLeft: 10, marginRight: 10 }}
                  >
                    <Column
                      style={{
                        flex: 9,
                        flexDirection: "column",
                      }}
                      onClick={() => handleClickRoute({ id, statusId })}
                    >
                      <Row>
                        <strong>
                          {transportDate?.substr(0, 10)} - {routeNumber} -{" "}
                          {routeType}
                        </strong>
                      </Row>
                      <Row>
                        {`${pickupsRequested} hämtning${
                          pickupsRequested === "1" ? "" : "ar"
                        } och ${droppoffsRequested} lämning${
                          droppoffsRequested === "1" ? "" : "ar"
                        }`}
                      </Row>
                    </Column>
                    <Column style={{ justifyContent: "flex-end" }}>
                      <DriverRouteStatusIcon statusId={statusId} />
                    </Column>
                  </Row>
                </Card>
              );
            }
          )}
        </>
      )}
    </Page>
  );
};

export default withRouter(DriverRoutes);
