import React from "react";
import styled from "styled-components";

import { ReactComponent as DigitalIcon } from "../../images/digital.svg";
import { ReactComponent as AnalogIcon } from "../../images/analog.svg";

import { Label } from "../../styles";

const Digital = styled(DigitalIcon)`
  fill: ${(props) => props.theme.grey};
  width: 30px;
  height: 30px;
`;

const Analog = styled(AnalogIcon)`
  fill: ${(props) => props.theme.grey};
  width: 30px;
  height: 30px;
  margin-bottom: 2px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrderTypeIcon = ({ type }) => {
  if (type === "Digital" || type === "Digitalt") {
    return (
      <Container>
        <Digital />
        <Label>Digital</Label>
      </Container>
    );
  } else if (type === "Analog" || type === "Analogt") {
    return (
      <Container>
        <Analog />
        <Label>Analog</Label>
      </Container>
    );
  } else {
    return <Container />;
  }
};

export default OrderTypeIcon;
