import React, { useState } from "react";
import styled from "styled-components";

import {
  Button,
  ButtonOutline,
  Item,
  Line,
  Spacer,
  Textarea,
} from "../../styles";

import { BACKEND_URL } from "../../config";
import { useFetch } from "../../utils";

const CommentForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const Panel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

/* select / option */
const DroppDown = styled.select`
  color: ${(props) => props.theme.darkBlue};
  border: 1px ${(props) => props.theme.lightgrey} solid;
  border-radius: 4px;
  padding: 15px;
  font-size: 1rem;
  outline: none;
  background-color: #fafafa;

  &:focus {
    border: 1px #d5d5d5 solid;
  }

  &:disabled {
    color: ${(props) => props.theme.lightgrey};
    background-color: ${(props) => props.theme.lightwhite};
    border: 1px ${(props) => props.theme.lightgrey} solid;
  }

  margin-bottom: 1rem;

  option {
    background-color: yellow;
  }
`;

const DroppDownItem = styled.option``;

const OrderCommentForm = ({
  orderId,
  setError,
  refetchComments,
  replyToComment,
  setReplyToComment,
}) => {
  const [comment, setComment] = useState("");
  const [emailComment, setEmailComment] = useState("");
  const [contact, setContact] = useState("");
  const [saving, setSaving] = useState(false);
  const [{ data: contacts, loading }] = useFetch({
    path: `/order-contacts/${orderId}`,
  });

  const handleSetNewComment = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleSetNewEmailComment = (e) => {
    const { value } = e.target;
    setEmailComment(value);
  };

  const handleSetContact = (e) => {
    const { value } = e.target;
    setContact(value);
  };

  const handleSaveNewComment = async () => {
    if (!comment) return;

    setSaving(true);

    try {
      const body = JSON.stringify({
        orderId,
        comment,
        email: contact || "",
      });

      const res = await fetch(`${BACKEND_URL}/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body,
      });

      if (res.status !== 200) {
        setError(res.statusText);
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json && json.status && json.status === 200) {
        refetchComments(new Date());
        setComment("");
        setContact("");
      }

      setSaving(false);
    } catch (error) {
      setError(error.message);
      setSaving(false);
      console.error(error.message);
    }
  };

  const handleSaveNewEmailReply = async () => {
    if (!emailComment) return;

    setSaving(true);

    try {
      const body = JSON.stringify({
        orderId,
        comment: emailComment,
        email: replyToComment.fromEmail,
      });

      const res = await fetch(`${BACKEND_URL}/comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body,
      });

      if (res.status !== 200) {
        setError(res.statusText);
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json && json.status && json.status === 200) {
        refetchComments(new Date());
        setEmailComment("");
        setReplyToComment("");
      }

      setSaving(false);
    } catch (error) {
      setError(error.message);
      setSaving(false);
      console.error(error.message);
    }
  };

  return (
    <CommentForm>
      <Item>
        {replyToComment ? (
          <Textarea
            rows="5"
            onChange={(e) => handleSetNewEmailComment(e)}
            value={emailComment}
          />
        ) : (
          <Panel>
            <DroppDown
              name="contacts"
              id="contacts"
              onChange={(e) => handleSetContact(e)}
              value={contact}
              disabled={loading}
            >
              <DroppDownItem value="">Välj mottagare</DroppDownItem>
              {!loading &&
                contacts &&
                JSON.parse(contacts).map((contact, index) => {
                  return (
                    <DroppDownItem
                      value={contact.email}
                      key={`${contact.email}-${index}`}
                    >
                      {contact.label
                        ? `${contact.label} - ${contact.email}`
                        : `${contact.email}`}
                    </DroppDownItem>
                  );
                })}
            </DroppDown>
            <Textarea
              rows="5"
              onChange={(e) => handleSetNewComment(e)}
              value={comment}
            />
          </Panel>
        )}

        <Spacer horizontal={14} />
        {replyToComment ? (
          <div>
            <Button
              onClick={() => handleSaveNewEmailReply()}
              width="100px"
              small
              disabled={saving}
            >
              Skicka svar
            </Button>
            &nbsp;&nbsp;&nbsp;
            <ButtonOutline
              onClick={() => setReplyToComment()}
              width="60px"
              small
              disabled={saving}
            >
              Ångra
            </ButtonOutline>
          </div>
        ) : (
          <Button
            onClick={() => handleSaveNewComment()}
            width="180px"
            disabled={saving}
          >
            Skicka kommentar
          </Button>
        )}
        <Spacer horizontal={14} />
        <Line />
      </Item>
    </CommentForm>
  );
};

export default OrderCommentForm;
